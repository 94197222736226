
<template>
  <div>
      <card-component title="Güncelle" icon="domain">
       <div class="columns is-multiline" style="padding: 0.75rem; background-color: #f7f7f7">
         <!--<div class="column is-6 p-0"><b>Banka:</b> {{ withdraw.bank.name }} - {{ withdraw.bankAccount.name }} ({{withdraw.bankAccount.iban}})</div>-->
         <div class="column is-6 p-0"><b>İsim:</b> {{ withdraw.name }}</div>
         <div class="column is-6 p-0"><b>Tutar:</b> ₺{{ withdraw.total }}</div>
         <div class="column is-6 p-0"><b>Kullanıcı Adı:</b> {{ withdraw.username }}</div>
         <div class="column is-6 p-0"><b>İşlem No:</b> {{ withdraw.processId }}</div>
         <div class="column is-6 p-0"><b>Kullanıcı ID:</b> {{ withdraw.userId }}</div>
         <div class="column is-6 p-0"><b>Kullanıcı Hesap:</b> {{ withdraw.accountName }}</div>
         <div class="column is-6 p-0"><b>Tarih:</b> {{ dateFormat(withdraw.createdAt, dateFormats.normalDateTime) }}</div>
         <div class="column is-6 p-0"><b>Kullanıcı IBAN:</b> {{ withdraw.iban }}</div>
       </div>
        <hr>
        <form @submit.prevent="save">
          <b-field label="Grup Adı">
            <b-input size="is-small" v-model="form.group" icon="domain" placeholder="Grup Adı" name="group"/>
          </b-field>
          <hr/>
          <div class="is-flex is-justify-content-end is-flex-grow-1">
            <div class="control mr-2">
              <b-button native-type="submit" type="is-primary" size="is-small" :loading="$store.state.loading.length > 0" :disabled="$store.state.loading.length > 0">Güncelle</b-button>
            </div>
            <div class="control">
              <b-button type="is-primary is-outlined" size="is-small" @click="$emit('close')">Kapat</b-button>
            </div>
          </div>
        </form>
      </card-component>
  </div>
</template>

<script>
import mapValues from 'lodash/mapValues'
import CardComponent from '@/components/CardComponent'
import { TransactionService as Service } from '@/services'

export default {
  name: 'WithdrawGroupChange',
  components: { CardComponent },
  props: ['withdraw'],
  data () {
    return {
      form: {
        group: null
      }
    }
  },
  mounted () {
    this.form.group = this.withdraw.group
  },
  methods: {
    save () {
      Service.withdrawGroupChange({uuid: this.withdraw.uuid, ...this.form })
          .then(() => {
            this.$buefy.toast.open({
              message: 'Güncellendi',
              type: 'is-success'
            })
            this.$emit('close')
          })
          .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
    },
    reset () {
      this.form = mapValues(this.form, (item) => {
        if (item && typeof item === 'object') {
          return []
        }
        return null
      })
    }
  }
}
</script>

<style>
.vm--modal {
  height: auto !important;
}
</style>
